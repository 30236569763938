import { createRouter, createWebHistory } from 'vue-router'
import ProductApp from '@/appMain/ProductApp.vue'
import apps from '@/views/apps.vue'

export default [{
    path: '/product',
    component: ProductApp,
    children: [
     
        {
            path: 'arts',
            name: 'arts',
            component: () => import('@/views/apps.vue')
        },
        {
            path: 'dis',
            name: 'dis',
            component: () => import('@/views/disApps/appList.vue')
        },
        {
            path: 'joinUs',
            name: 'joinUs',
            component: () => import('@/views/disApps/UserInfoForm.vue')
          },
      

    ]
}]

