import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import apps from './apps'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/softs',
    name: 'softs',
    component: () => import('../views/softs.vue')
  },

  {
    path: '/memo/:id',
    name: 'detail',
    component: () => import('../views/memo/MemoDetail.vue')
  },
  {
    path: '/discoverApps',
    name: 'discoverApps',
    component: () => import('../views/disApps/appList.vue')
  },
  {
    path: '/edit',
    name: 'edit',
    component: () => import('@/views/edit.vue')
  },
  {
    path: '/thridget',
    name: 'edit',
    component: () => import('@/views/thridDown/MainView.vue')
  },
  {
    path: '/waitDeal',
    name: 'waitDeal',
    component: () => import('@/views/thridDown/waitDealList.vue')
  },
  ...apps,
  
]

const router = createRouter({ 
  history: createWebHistory(),
  routes
});
router.beforeEach((to, from, next) => {
  const softDomain = 'soft.qklchain.com';
  const appsDomain = 'product.qklchain.com';
  // 获取当前访问的域名
  const currentDomain = window.location.hostname;
  console.info(`currentDomain=${currentDomain}`)
  //根据不同的域名进行重定向
  if (to.path === '/') {
    if (currentDomain == 'localhost') {
      next('/product/arts');
    }
    else if (currentDomain === softDomain) {
      next('/softs');
    }
    else if (currentDomain === appsDomain) {
      next('/product/arts');
    }
    else {
      next ()
    }
  }
  else {
    next();
  }
  
});
export default router
