<script setup lang="ts">
import { ref, defineProps, defineEmits, type Ref, onMounted } from 'vue'
import PriceItem from '@/components/muser/charge/PriceItem.vue'

const text = ref(null)
const props = defineProps(['show'])
const emits = defineEmits(['update:show'])
onMounted(() => {

})
const updateShow = (v) => {
    emits('update:show', v)
}
const datas = [
    {
        userleve: '1',
        userLevel_desc: '高级',
        price: '100',
    },
]
const show = ref(false)
</script>
<template>
    <n-modal :show="props.show" @update:show="updateShow">
        <n-card style="width: 800px;">
            <n-space vertical align="center">
                <span id="myElement"></span>
                <h2>订购会员</h2>
                <n-space>
                    <PriceItem v-for="(item, index) in datas" :key="index" :data="item">
                    </PriceItem>
                </n-space>
            </n-space>
        </n-card>
    </n-modal>
</template>
<style scoped>
.describ {
    font-size: 15px;
    font-weight: 100;
}
</style>