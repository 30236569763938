<script setup lang="ts">
import { ref, defineEmits, reactive } from 'vue'
import { registerSubmit, sendCodeApi } from "../api/index"

import { useMessage, type CountdownInst } from 'naive-ui';
const message = useMessage()
const isSendCode = ref(false)
const showSpin = ref(false)
const active = ref(false)
const sendCodeLabel = ref('发送验证码')
const sendCodeDisabled = ref(false)
const countdown = ref<CountdownInst | null>()
const emit = defineEmits(['tologin', 'toregister', 'toforgetPwd', 'register-cancel'])
let userinfo = reactive({
    userName: '',
    mail: '',
    code: '',
    password: '',
    repassword: ''

})
const sendCode = async () => {
    const data = { 'mail': userinfo.mail }
    try {
        const res = await sendCodeApi(data)
    } catch (error) {
        console.info('catch到异常了')
    }

    active.value = true
    isSendCode.value = true
    message.success('已经发送验证码到邮箱:' + userinfo.mail)
}
const resetPwd = () => {
    emit('toforgetPwd', 'toforgetPwd')
}
const tologin = () => {
    message.info(`跳转到登录`)
    emit('tologin', 'tologin')
}
function finish() {
    active.value = false
    sendCodeLabel.value = '重发验证码'
    sendCodeDisabled.value = false
    countdown.value?.reset()


}
function renderCountdown(props: any) {

    if (active.value == true) {
        sendCodeDisabled.value = true
        sendCodeLabel.value = '重新发送:' + props.seconds
    }
    else
        sendCodeLabel.value = '发送验证码'
}

const submit = async () => {
    if (userinfo.password != userinfo.repassword) {
        message.info('密码输入不一致')
        return
    }

    showSpin.value = false
    try {
        const res = await registerSubmit(userinfo);
        console.info(`注册成功`)
        tologin();
    } catch (error: any) {
        message.info(error)
        return
    }
    finally {
        showSpin.value = true
    }
}



const cancel = () => {
    emit('register-cancel', '')

}



</script>
<template>
    <n-spin :show="showSpin">
        <n-card style="width: 400px ; margin: auto;">
            <n-space justify="center" align="start" vertical>
                <span>用户邮箱</span>
                <n-input v-model:value="userinfo.userName" style="width: 400px;" placeholder="用户名称"></n-input>
                <n-space>
                    <n-input label="邮箱地址" v-model:value="userinfo.mail" style="width:400px"
                        placeholder="邮箱地址"></n-input>

                </n-space>
                <n-button :disabled="sendCodeDisabled" @click="sendCode" type="primary" size="small">
                    <n-countdown ref="countdown" :render="renderCountdown" :duration="9 * 1000" :active="active"
                        :on-finish="finish" />{{
                            sendCodeLabel }}</n-button>
                <span>验证码</span>
                <n-input style="width:400px" :disabled="!isSendCode" v-model:value="userinfo.code"
                    placeholder="请输入验证码"></n-input>
                <span>用户密码</span>
                <n-input style="width:400px" :disabled="!isSendCode" v-model:value="userinfo.password" type="password"
                    placeholder="输入用户密码"></n-input>
                <span>重新输入密码</span>
                <n-input style="width:400px" :disabled="!isSendCode" v-model:value="userinfo.repassword" type="password"
                    placeholder="请重复输入用户密码"></n-input>
                <n-space>
                    <n-button @click="submit" type="primary" style="width: 350px;">提交</n-button>
                </n-space>
                <n-space vertical align="center" style="width: 300px;">
                    <n-button text style="color:rgb(160, 107, 210)" @click="tologin">有账号,去登录?</n-button>
                    <n-button text style="color:rgb(184, 180, 188)" @click="resetPwd">有账号,忘记密码?</n-button>
                </n-space>
            </n-space>
        </n-card>
    </n-spin>
</template>
<style scoped></style>