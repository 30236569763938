<script setup lang="ts">
import { ref, defineProps, defineEmits } from 'vue'
const props = defineProps<{ data: DataType }>()
//const props = defineProps(['data'])
interface DataType {
    userleve: ''
    userLevel_desc: '',
    price: '',
}
const toCharge = () => {

}
</script>

<template>
    <n-card style="width: 300px;">
        <n-space vertical align="center">
            <span>{{ $props.data.userLevel_desc }}</span>
            <span class="describ">For individuals to test the</span>
            <span class="describ">No credit,card,required</span>
            <span>{{ props.data.price }}元/mo</span>

            <n-space vertical align="start">
                <n-checkbox :checked=true>50 apps/AI Chats per month</n-checkbox>
                <n-checkbox :checked=true>GPT-3.5</n-checkbox>
                <n-checkbox :checked=true>10 AI images per month</n-checkbox>
                <n-checkbox :checked=true>3 custom apps</n-checkbox>
                <n-checkbox :checked=true>User seat: 1</n-checkbox>
            </n-space>
            <n-button @click="toCharge" type="primary" size="large" round>购买</n-button>
        </n-space>

    </n-card>
</template>

<style scoped></style>