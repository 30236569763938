const env = import.meta.env
console.info('输出env:'+JSON.stringify(env))
import './tailwind.css';
import './assets/main.css'
// import { useUserStore } from '@/stores/user'
import { createApp } from 'vue'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import store from './store'
import naive from "naive-ui"
import moment from "moment-timezone"
import axios from 'axios'
axios.defaults.baseURL = 'https://www.escook.cn'
const app = createApp(App)
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate)
app.use(naive)
app.use(pinia)
app.use(router)
app.config.globalProperties.$http = axios
app.mount('#app')
