<script setup lang="ts">
import { ref, defineEmits, defineProps, reactive } from 'vue'
import { loginApi } from "../api/index"

import { useUserStore } from '../stores/user'
import { useMessage } from 'naive-ui';
const message = useMessage()

const username = ref('')
const password = ref('')
const show = ref(false)
const emit = defineEmits(['logindone', 'toregister', 'toforgetPwd'])

const login = async () => {
    if (username.value == '') {
        message.info("用户名为空"); return
    }
    if (password.value == '') { message.info("密码为空"); return }
    const userinfo = { 'loginName': username.value, 'password': password.value }
    show.value = true
    let res = null;
    try {
        res = await loginApi(userinfo)
    } catch (error: any) {
        message.info(error)
        return
    }
    finally {
        show.value = false
    }
    const userStore = useUserStore()
    const userId = res.result.userId
    const userName = res.result.userName
    //保存用户登录状态
    userStore.login(userId, userName)
    //await userStore.initSubs()
    message.info('登录成功')
    emit('logindone', '')

}
const cancel = () => {
    emit('toforgetPwd', '')
}
const resetPwd = () => {
    emit('toforgetPwd', 'toforgetPwd')

}
const register = () => {
    console.info('toregister')
    emit('toregister', 'toregister')

}
</script>

<template>
    <n-card style="width: 400px; padding: 10px; margin: auto;">
        <template #header>M-Link登录</template>

        <template #default>
            <div style="width: 100%;">
                <n-spin :show="show">
                    <n-space vertical align="start">
                        <span style="font-weight: 50; color: rgb(169, 172, 175);">继续，即表示您同意M.link的服务条款和隐私声明</span>
                        <span>用户名/邮箱</span>

                        <n-input style="width: 300px;" placeholder="输入用户名" @keyup.enter="login"
                            v-model:value="username"></n-input>

                        <span>密码</span>
                        <n-input style="width: 300px;" placeholder="输入密码" @keyup.enter="login" type="password"
                            show-password-on="click" v-model:value="password"></n-input>

                        <n-space>
                            <n-button style="width: 300px;" @click="login" @keyup.enter="login"
                                type="primary">提交</n-button>
                        </n-space>

                        <n-space vertical align="center" style="width: 300px;">
                            <n-button text style="color:rgb(160, 107, 210)" @click="register">没有账号,注册?</n-button>
                            <n-button text style="color:rgb(184, 180, 188)" @click="resetPwd">有账号,忘记密码?</n-button>
                        </n-space>

                    </n-space>
                </n-spin>
            </div>
        </template>

    </n-card>
</template>

<style scoped></style>
