<script setup lang="ts">
import { NConfigProvider, type GlobalThemeOverrides, useMessage } from 'naive-ui'
const themeOverrides_default: GlobalThemeOverrides = {
  common: {
    primaryColor: '#3730a3',
    primaryColorHover: '#211d62',
    textColorBase: '#6e35ae',
    hoverColor: '#f0eaf7',
  },

  Button: {
    textColor: '#6e35ae',

  }
}</script>
<template>
  <n-config-provider :theme-overrides="themeOverrides_default" style="">
    <n-message-provider>
      <n-dialog-provider>
        <router-view />
      </n-dialog-provider>
    </n-message-provider>
  </n-config-provider>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
