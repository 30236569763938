<script setup lang="ts">
import { resetPwdApi, sendCodeApi } from '../api/index'

import { ref, reactive, } from 'vue'
import { useMessage, type CountdownInst } from 'naive-ui';
import { useRouter } from 'vue-router';
const router = useRouter()
const message = useMessage()
const sendCodeLabel = ref('发送验证码')
const countdown = ref<CountdownInst | null>()
const sendCodeDisabled = ref(false)
const emit = defineEmits(['tologin'])
const sendCode = async () => {
    const data = { 'mail': resetInfo.mail }
    const res = await sendCodeApi(data).catch((error: any) => {
        message.info(error)
        return
    }).finally(() => {


    })
    active.value = true
    message.success('已经发送验证码到邮箱:' + resetInfo.mail)
}
const resetInfo = reactive({
    mail: '',
    code: '',
    password: '',
    repassword: ''
})
const submit = async () => {
    const res = await resetPwdApi(resetInfo).catch((error: string) => {
        message.info(error)
        return
    }).finally(() => {

    })
    router.push('/userLogin')

}
const tologin = () => {
    emit('tologin', 'tologin')
}
const finish = () => {
    active.value = false
    sendCodeLabel.value = '重发验证码'
    sendCodeDisabled.value = false
    countdown.value?.reset()
}
function renderCountdown(props: any) {

    if (active.value == true) {
        sendCodeDisabled.value = true
        sendCodeLabel.value = '重新发送:' + props.seconds
    }
    else
        sendCodeLabel.value = '发送验证码'
}
const active = ref(false)
</script>
<template>
    <n-card style="width: 400px; margin:auto">
        <n-space vertical>
            <span>邮箱地址</span>
            <n-input v-model:value="resetInfo.mail" placeholder="输入你的邮箱地址">邮箱地址</n-input>
            <n-button type="primary" :disabled="sendCodeDisabled" size="small" @click="sendCode">
                <span> <n-countdown ref="countdown" :render="renderCountdown" :duration="9 * 1000" :active="active"
                        :on-finish="finish" />{{ sendCodeLabel }}</span></n-button>

            <n-input v-model:value="resetInfo.code" placeholder="输入验证码"></n-input>

            <n-input v-model:value="resetInfo.password" placeholder="输入新密码"></n-input>
            <n-input v-model:value="resetInfo.repassword" placeholder="请重新输入新密码"></n-input>
            <n-button @click="submit" type="primary" style="width: 100%;">提交重置</n-button>
            <n-button text style="color:rgb(160, 107, 210)" @click="tologin">有账号,去登录?</n-button>

        </n-space>
    </n-card>
</template>
<style scoped></style>