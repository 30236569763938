//首页
<script setup lang="ts">
import { useUserStore } from "@/components/muser/stores/user"
const userStore = useUserStore()
import { messageDark, useMessage } from 'naive-ui';

const message = useMessage()
import { useRouter } from 'vue-router'
import { ref, defineEmits, watch, onMounted, defineExpose } from 'vue'

import { Settings24Filled } from '@vicons/fluent'
import LoginPanel from '@/components/muser/login/LoginPanel.vue';
import ResetPassword from '@/components/muser/login/ResetPassword.vue';
import RegisterPanel from '@/components/muser/login/RegisterPanel.vue';
import OrderProductModal from './OrderProductModal.vue'
const emit = defineEmits(['close', 'updateLog', 'recentPlan'])

const router = useRouter()

const handleSelect = (key: string | number) => {
    if (key == 'modifyPwd') {
        showLogin.value = true;
    }
    else if (key == 'logout') {
        userStore.logout('')
        userId.value = null;
        username.value = '未登录'
        message.info('退出成功')
        router.push('/')
    }
    else if (key == 'updateLog') {
        emit('updateLog', '')
    }
    else if (key == 'recentPlan') {
        console.info('点击了recentPlan')
        emit('recentPlan', '')
    }
}
const username = ref('未登录')
const userId = ref<null | string>(null);
const options = [
    {
        label: '修改密码',
        key: 'modifyPwd'
    },
    {
        label: '退出登录',
        key: 'logout'
    },

]
const upgrade = () => {
    showOrder.value = true;
}
const login = () => {
    showLogin.value = true;


}
defineExpose({ login })

const showLogin = ref(false)
const closeModal = () => {
    showLogin.value = false
}
const loginDone = () => {
    const name = userStore.userName as string;
    userId.value = userStore.userId;
    username.value = name;
    closeModal();
}
const toChange = (type: string) => {
    showType.value = type
    console.info(`showType=${showType.value}`)
}
const showOrder = ref(false)
const showType = ref("tologin")
const loginHandler = () => {
    if (userId.value != null) {
        return;
    }
    else {
        login()
    }
}
onMounted(() => {
    const storeUserId = userStore.userId
    if (storeUserId != null) {
        userId.value = storeUserId;
        username.value = userStore.userName;
    }
    console.info(` get userId =${storeUserId}`)
})
</script>
<template>
    <n-popover style="max-height: 240px" trigger="hover" scrollable>
        <template #trigger>
            <n-avatar @click="loginHandler" style="margin-top: 10px; width: 30px; height: 30px;" round size="small"
                src="https://mutou.qklchain.com/share/ator.png" />
        </template>
        <n-space vertical>
            <n-space justify="space-between">
                <n-space><n-avatar round size="small" src="https://07akioni.oss-cn-beijing.aliyuncs.com/07akioni.jpeg"
                        fallback-src="https://07akioni.oss-cn-beijing.aliyuncs.com/07akioni.jpeg" />
                    <span>{{ username }}</span>
                    <n-button v-if="userId == null && userId != ''" type="primary" @click="login">去登录</n-button>
                </n-space>
                <n-dropdown placement="bottom-start" trigger="hover" size="small" :options="options"
                    @select="handleSelect">
                    <n-icon :component="Settings24Filled" size="20" :depth="1" />
                </n-dropdown>

            </n-space>
            <div v-if="false" style=" background-color: #9fb1ce; padding: 10px; border-radius: 5px;">
                <n-space justify="space-between"><span>Free</span>
                    <n-button @click="upgrade" type="success" size="small" round>
                        升级计划
                    </n-button>
                </n-space>
                <span style="color: aliceblue;">升级计划以获取更多的功能和速度</span>
            </div>

        </n-space>
    </n-popover>
    <n-modal v-model:show="showLogin">
        <loginPanel v-if="showType == 'tologin'" @logindone="loginDone" @toregister="toChange" @toforgetPwd="toChange">
        </loginPanel>
        <RegisterPanel v-if="showType == 'toregister'" @tologin="toChange" @toregister="toChange"
            @toforgetPwd="toChange">
        </RegisterPanel>
        <ResetPassword v-if="showType == 'toforgetPwd'" @tologin="toChange" @toregister="toChange"
            @toforgetPwd="toChange">
        </ResetPassword>

    </n-modal>
    <OrderProductModal v-model:show="showOrder"></OrderProductModal>
</template>
<style scoped></style>