import { defineStore,createPinia } from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

interface UserInfo {
  userId: string,
  userName:string
}
interface State {
  token: string | null;
  userId: string,
  userName:string,
  userInfo: UserInfo;
}
const pinia = createPinia();
// 使用持久化插件
pinia.use(piniaPluginPersistedstate);
export const useUserStore = defineStore({
  id: "user",
  state: (): State => ({
    token: '',
    userId: '',
    userName: '',
    userInfo: { userId: '', userName: '' },
  }),
actions: {
  checkLogin(context:any,current: string, router: any) {

    console.info(`check 用户登录`)
    if (context.state.userId == '') {
      console.info(`跳转到登录`)
      router.push({ path: '/userLogin', query: { returnRedict: current } })
      return false;
    }
    return true;
  },
   
    logout(info:string) {
      this.userId = '';
      this.userName = '';

    },
  
  login(userId: string, userName: string) {
    this.userId = userId
    this.userName=userName
  },

    getUserInfo(context:any):UserInfo {
      return {'userId':context.state.userId,'userName':context.state.userName}
    },
  },
  getters: {
    user: (state) => {
      return {'userId':state.userId,'userName':state.userName}
    },
    isLogin: (state) => {
      return state.userId == '' ? false : true;
    },
   
  },
  persist: {
    key: 'user-store',
    storage: localStorage, // 使用 localStorage 进行持久化存储
  },
  
});