<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import UserHeader from '@/components/UserHeader.vue';
import { useUserStore } from "@/components/muser/stores/user"
const userStore = useUserStore()
const toEditStatus = () => {
    return false;
}
const isEdit = computed(() => {
    return false;
})
const userHeaderRef = ref(null) as any;
onMounted(() => {
    if (userStore.userId == null || userStore.userId == '') {
        userHeaderRef.value.login()
    }
})

</script>
<template>
    <nav class="  bg-indigo-700">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex items-center justify-between h-16">
                <n-space align="center">

                    <router-link to="/product/arts"
                        class="text-gray-600 hover:text-white px-3 py-2 rounded-md no-underline bg-violet-100">首页</router-link>
                    <router-link to="/product/dis"
                        class="text-gray-600 hover:text-white px-3 py-2 rounded-md no-underline bg-violet-100">发现app</router-link>
                    <user-header ref="userHeaderRef"></user-header>
                </n-space>



            </div>
        </div>
    </nav>
</template>
