//参考源代码:https://gitee.com/xxpromise/vue3-axios/blob/master/client/src/utils/request.ts
import axios from "axios";
const env = import.meta.env
import type { AxiosRequestConfig } from "axios";
import { useUserStore } from "../stores/user"
const baseUrl = env.VITE_BASE_URL
console.info(`baseUrl=${baseUrl}`)
const baseAiUrl=env.VITE_AI_URL
const request = axios.create({
  // 需要在 .env.* 文件中定义环境变量才能使用
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
},
  timeout: 60000,
});
request.interceptors.request.use((config) => {
  config.headers['Content-Type'] = 'application/json'
  config.headers['userId'] = useUserStore().user.userId
  console.info('%c请求url:%s', "color: green;font-weight: bold;", JSON.stringify(config.url))
  console.info('%c请求参数:%s',"color: green;font-weight: bold;",JSON.stringify(config.data))
  return config;
});

request.interceptors.response.use(
  (response) => {
    console.info('%c响应:%s',"color: green;font-weight: bold;",JSON.stringify(response.data))
    //removeRequestKey(response.config); // 删除key
    const code = response.data.code;

    if (code === 200) {
      return response.data;
    } else {
      console.error('出错了')
      let errMessage = authErrMessage[code];
      if (errMessage) {
        // 功能出错，需要特殊处理，比如要退出登录等
        // useUserStore().logout();
      } else {
        errMessage = response.data.message;
      }
      console.info('%解析到错误信息:%s',"color: red;font-weight: bold;",JSON.stringify(errMessage))
      return Promise.reject(errMessage);
    }
   
  },
  (error) => {
    //error.config && removeRequestKey(error.config); // 删除key
    let errMessage = "未知错误，请联系管理员解决";
    if (error.response) {
      // error.response有值，说明服务器有响应，响应结果是错误的，要根据响应状态码error.response.status来提示错误
      errMessage = networkErrMessage[error.response.status];
    } else {
      // error.response没有值，说明服务器没有响应，请求在客户端就失败了，要根据浏览器提示的错误信息来处理
      if (error.message.indexOf("timeout") > -1) {
        // 请求超时
        errMessage = "当前网络环境不稳定，请切换4/5G网络或WIFI网络试试";
      } else if (error.message.indexOf("Network") > -1) {
        // 断网了
        errMessage = "没有检测到网络，请打开网络链接试试";
      } else if (error.message.indexOf("canceled") > -1) {
        errMessage = "请求取消了";
      }
    }
    console.info('%c解析到系统异常:%s', "color: green;font-weight: bold;", JSON.stringify(errMessage))
    return Promise.reject(errMessage);
  }
);
export const loginApi = (userinfo:any) => {
  return request.post<any,any>('/userlogin',userinfo)
}
export const getUserSubsApi = (data:any) => {
  return request.post<any,any>('/getUserSubs',data)
}
export const registerSubmit = (userinfo: any) => {

  return request.post<any,any>("/register",userinfo)
}
export const sendCodeApi = (data: any) => {
  return request.post<any,any >("/sendCode",data)
}
export const resetPwdApi = (data:any) => {
  return request.post<any,any>("/resetPwd",data)
}
export const payApi = (data:any) => {
  return request.post<any,any>("/pay",data)
}
export const webchatpayApi = (data:any) => {
  return request.post<any,any>("/searchMemHints",data)
}
export const getUsageApi = (data:any) => {
  return request.post<any,any>("/getUsageApi",data)
}
export const getUserBalance = (data:any) => {
  return request.post<any,any>("/getUserBalance",data)
}




